.form {
  background-color: #fff;
  border-radius: 1rem;
  padding: 1rem;
  border: thin solid;
  border-color: rgb(220, 221, 228);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  max-width: 800px;
  width: 95%;
  margin: 1rem auto;
}

.header {
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: rgb(249, 249, 250);
  border-radius: .5rem;
}
  
  .buttons {
    display: flex;
    border-top: thin solid;
    margin-top: 1rem;
    border-color: rgb(220, 221, 228);
    justify-content: flex-end;
  }
  
  .button {
    margin-top: 16px;
    margin-left: 16px;
  }
  
  .wrapper {
    margin: 8px;
    position: relative;
  }
  
  .buttonProgress {
    position: absolute;
    top: 50%;
    left: 50%;
  }
  