.filterContainer,
.endColumn {
  display: flex;
  column-gap: 10px;
  align-items: center;
}
.filterContainer{
  max-width: 100%;
}

.endColumn {
  height: 100%;
  align-items: center;
}

.filterOptions {
  padding-right: 10px;
}

.filterOptions > div{
  width: 100%;
  flex: 1
}

.filterBottom {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 1rem 0; /* Adjust as needed */
  }
  
  .filterBottom::before,
  .filterBottom::after {
    content: "";
    flex: 1;
    height: 1px;
    background-color: #000;
    border-bottom: 1px dashed #000;
  }
  
  .filterBottom > div {
    margin: 0 1rem;
  }
  
  