.container {
    padding: 1rem 1.5rem;
}

.form {
    background-color: #fff;
    border-radius: 8px;
    padding: 1rem;
    border: thin solid;
    border-color: rgb(220, 221, 228);
    box-shadow:
        rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
        rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    width: 95%;
    margin: 1rem auto;
}

.form.invisible {
    display: none;
}

.listItemContainer {
    max-height: 100px;
    overflow-y: auto;
    display: block;
    max-width: 800px;
}

.listItem {
    display: block;
}

.listItemText {
    white-space: normal;
}
