.dialogPaper {
    border-radius: 1rem;
}

.dialogTitle {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10;
    padding: 8px 0 0 0;
    border-radius: 4rem;
}

.selectedCount {
    width: 100%;
    padding: 4px 1rem;
    background-color: rgb(234, 239, 242);
    font-weight: 900;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dialogContent {
    max-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 0;
}

.searchInput {
    margin-bottom: 8px;
}

.categoryList {
    padding: 0;
}

.categoryTitle {
    margin-top: 16px;
}

.listItem {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    border-left: 1px solid rgb(188, 202, 210);
    margin-left: 26px;
    padding: 12.5px 26px;
    height: 80px;
}

.listItem:after {
    position: absolute;
    height: 6px;
    width: 16.5px;
    border-bottom: 1px solid rgb(188, 202, 210);
    content: "";
    left: 0px;
}

.listItem.lastItem {
    border: none
}

.listItem.lastItem:after {
    height: 33px;
    top: 0px;
    border-left: 1px solid rgb(188, 202, 210);
}

.dialogActions {
    position: sticky;
    bottom: 0;
    background-color: white;
    z-index: 10;
}

.iconButton {
    border-radius: 50%;
    background: rgba(199, 212, 224, 0.4);
    border: 2px solid gray;
}

.iconButton.selected {
    background: #51E07C;
    color: white;
    border: none;
}

.continueButton {
    width: 100%;
    border-radius: 8px;
}

.noOutline {
    border: none;
}

.searchInput .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
}

.secondaryText {
    margin: 0;
    font-size: 0.75rem;
    line-height: 1.66;
    font-family: 'Public Sans', sans-serif;
    font-weight: 400;
    color: #8c8c8c;
}