.accordion {
    background-color: #fff;
    border: 1px solid #d7dade;
    border-radius: 8px !important;
    box-shadow: 0px 2px 3px -1px rgba(0, 0, 0, 0.08);
}
.accordionTitle {
    border-bottom: 1px solid #ffffff;
    transition: border-color 0.2s ease-in-out;
}

.accordionTitle.expanded {
    border-bottom: 1px solid #d7dade;
}
.accordionContent {
    padding: 12px;
}
